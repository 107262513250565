import {WidgetBuilder} from '../../editor.app.types';
import {ctaButtonsDesignBuilderFn} from '../global-design/cta.widget.design';
import {helpIds} from '../../../constants/app';
import {ILocaleKeys} from '../../../locale-keys/LocaleKeys';
import {ActionIds} from '../../../constants/gfpp';
import {CtaButtonStates} from '../../../components/ctaButtonsWidget/config/constants';
import {FlowAPI} from '@wix/yoshi-flow-editor';
import {SPECS} from '../../../specs';

export const setCtaButtonsWidgetStageBehavior = (
  widgetBuilder: WidgetBuilder,
  t: ILocaleKeys,
  flowAPI: FlowAPI,
): void => {
  const isWidgetViewStatesEnabled = flowAPI.experiments.enabled(SPECS.WIDGET_VIEW_STATES_ON_CTA);

  widgetBuilder.set({displayName: t.productPage.actionButtonsWidget.displayName()});

  widgetBuilder.behavior().set({removable: false});

  if (isWidgetViewStatesEnabled) {
    configureWidgetViewStates(widgetBuilder, t);
  }

  widgetBuilder
    .gfpp()
    .set('mainAction1', {
      label: isWidgetViewStatesEnabled
        ? t.productPage.actionButtonsWidget.gfpp.design()
        : t.productPage.actionButtonsWidget.gfpp.changeView(),
      actionId: isWidgetViewStatesEnabled ? '' : ActionIds.CTA_CHANGE_STATE,
      actionType: isWidgetViewStatesEnabled ? 'design' : undefined,
    })
    .set('mainAction2', {behavior: 'HIDE'})
    .set('settings', {behavior: 'HIDE'})
    .set('layout', {actionId: ActionIds.CTA_LAYOUT})
    .set('connect', {behavior: 'HIDE'})
    .set('add', {behavior: 'HIDE'})
    .set('help', {id: helpIds.CTA_BUTTONS});

  widgetBuilder
    .gfpp('mobile')
    .set('layout', {actionId: ActionIds.CTA_LAYOUT})
    .set('mainAction1', {behavior: 'HIDE'})
    .set('mainAction2', {behavior: 'HIDE'})
    .set('settings', {behavior: 'HIDE'})
    .set('hide', {behavior: 'HIDE'});

  widgetBuilder.configureWidgetDesign((widgetDesignBuilder) => ctaButtonsDesignBuilderFn(widgetDesignBuilder, t));
};

const configureWidgetViewStates = (widgetBuilder: WidgetBuilder, t: ILocaleKeys): void => {
  widgetBuilder
    .configureWidgetViewState(CtaButtonStates.AddBuy, (stateBuilder) => {
      stateBuilder.set({
        initial: true,
        title: t.productPage.actionButtonsWidget.changeView.addToCartBuyNow.option(),
      });
    })
    .configureWidgetViewState(CtaButtonStates.PreOrder, (stateBuilder) => {
      stateBuilder.set({
        title: t.productPage.actionButtonsWidget.changeView.preOrder.option(),
      });
    })
    .configureWidgetViewState(CtaButtonStates.OutOfStock, (stateBuilder) => {
      stateBuilder.set({
        title: t.productPage.actionButtonsWidget.changeView.outOfStock.option(),
      });
    })
    .configureWidgetViewState(CtaButtonStates.Subscribe, (stateBuilder) => {
      stateBuilder.set({
        title: t.productPage.actionButtonsWidget.changeView.subscribe.option(),
      });
    })
    .configureWidgetViewState(CtaButtonStates.NotifyMe, (stateBuilder) => {
      stateBuilder.set({
        title: t.productPage.actionButtonsWidget.changeView.notifyMe.option(),
      });
    });
};
