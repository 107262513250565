import {helpIds} from '../../constants/app';
import {FlowEditorSDK} from '../editor.app.types';

export const openProGalleryLayoutPanel = (editorSDK: FlowEditorSDK, componentRef) => {
  void editorSDK.editor.openNativeComponentPanel('', 'layout', {
    componentRef,
    helpId: helpIds.PRO_GALLERY,
    configuration: {
      controls: {
        'layoutParams_structure_galleryLayout.5': {hidden: true},
        'layout.newInfoElements': {hidden: true},
        'layout.info-old': {hidden: true},
        'layout.info-new': {hidden: true},
        controllers_layoutParams_structure_galleryRatio_method: {hidden: true},
      },
      // TODO - Need to be typed but there are no types for Gallery controls
    } as unknown as never,
  });
};
