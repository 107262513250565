import {WidgetBuilder, ConnectedComponentsBuilder, FlowEditorSDK} from '../../editor.app.types';
import {ILocaleKeys} from '../../../locale-keys/LocaleKeys';
import {getRole} from '../global-design/getRole';
import {
  CtaButtonsWidgetComponentIds as ComponentIds,
  CtaContainersComponentsIds,
} from '../../../components/ctaButtonsWidget/config/constants';
import {ActionIds} from '../../../constants/gfpp';
import {stylableButtonsPanel} from '../../panels/stylableButtonPanels';
import {setComponentsAsUnselectable} from './helpers/setComponentsAsUnselectable';
import {setTextComponentStageBehavior} from './helpers/setTextComponentStageBehavior';
import {FlowAPI} from '@wix/yoshi-flow-editor';
import {SPECS} from '../../../specs';

export const setCtaButtonsWidgetInnerStageBehavior = (
  widgetBuilder: WidgetBuilder,
  t: ILocaleKeys,
  editorSDK: FlowEditorSDK,
  flowAPI: FlowAPI,
): void => {
  const useCtaSettingsMobile = flowAPI.experiments.enabled(SPECS.CtaSettingsMobile);

  widgetBuilder
    .configureConnectedComponents(
      getRole(ComponentIds.AddToCartBtn),
      (innerWidgetBuilder: ConnectedComponentsBuilder) => {
        innerWidgetBuilder.set({displayName: t.productPage.addToCartButton.displayName()});
        stylableButtonsPanel.setPanelWithHiddenLink(innerWidgetBuilder);
        innerWidgetBuilder
          .gfpp()
          .set('settings', {actionId: ActionIds.ADD_TO_CART_SETTINGS})
          .set('connect', {behavior: 'HIDE'})
          .set('link', {behavior: 'HIDE'});
        innerWidgetBuilder
          .gfpp('mobile')
          // @ts-expect-error: No support for overloaded methods
          .set('settings', useCtaSettingsMobile ? {actionId: ActionIds.ADD_TO_CART_SETTINGS} : {behavior: 'HIDE'})
          .set('mainAction1', {behavior: 'HIDE'})
          .set('mainAction2', {behavior: 'HIDE'})
          .set('layout', {behavior: 'HIDE'})
          .set('hide', {behavior: 'HIDE'});
      },
    )
    .configureConnectedComponents(getRole(ComponentIds.BuyNowBtn), (innerWidgetBuilder) => {
      innerWidgetBuilder.set({displayName: t.productPage.buyNowButton.displayName()});
      stylableButtonsPanel.setPanelWithHiddenLink(innerWidgetBuilder);
      innerWidgetBuilder
        .gfpp()
        .set('settings', {behavior: 'HIDE'})
        .set('connect', {behavior: 'HIDE'})
        .set('link', {behavior: 'HIDE'});
    })
    .configureConnectedComponents(getRole(ComponentIds.OutOfStockBtn), (innerWidgetBuilder) => {
      innerWidgetBuilder.set({displayName: t.productPage.outOfStockButton.displayName()});
      stylableButtonsPanel.setPanelWithHiddenLink(innerWidgetBuilder);
      innerWidgetBuilder
        .gfpp()
        .set('settings', {behavior: 'HIDE'})
        .set('connect', {behavior: 'HIDE'})
        .set('link', {behavior: 'HIDE'});
    })
    .configureConnectedComponents(getRole(ComponentIds.SubscribeNowBtn), (innerWidgetBuilder) => {
      innerWidgetBuilder.set({displayName: t.productPage.subscribeNowButton.displayName()});
      stylableButtonsPanel.setPanelWithHiddenLink(innerWidgetBuilder);
      innerWidgetBuilder
        .gfpp()
        .set('settings', {behavior: 'HIDE'})
        .set('connect', {behavior: 'HIDE'})
        .set('link', {behavior: 'HIDE'});
    })
    .configureConnectedComponents(getRole(ComponentIds.NotifyMeBtn), (innerWidgetBuilder) => {
      innerWidgetBuilder.set({displayName: t.productPage.notifyMeButton.displayName()});
      stylableButtonsPanel.setPanelWithHiddenLink(innerWidgetBuilder);
      innerWidgetBuilder
        .gfpp()
        .set('settings', {behavior: 'HIDE'})
        .set('connect', {behavior: 'HIDE'})
        .set('link', {behavior: 'HIDE'});
    })
    .configureConnectedComponents(getRole(ComponentIds.PreOrderButton), (innerWidgetBuilder) => {
      innerWidgetBuilder.set({displayName: t.productPage.preOrderButton.displayName()});
      stylableButtonsPanel.setPanelWithHiddenLink(innerWidgetBuilder);
      innerWidgetBuilder
        .gfpp()
        .set('settings', {actionId: ActionIds.CTA_PRE_ORDER_SETTINGS})
        .set('connect', {behavior: 'HIDE'})
        .set('link', {behavior: 'HIDE'});
    })
    .configureConnectedComponents(getRole(ComponentIds.PreOrderMessage), (innerWidgetBuilder) => {
      setTextComponentStageBehavior(
        innerWidgetBuilder,
        editorSDK,
        t.productPage.preOrderMessageWidget.displayName(),
        t.productPage.preOrderMessageWidget.gfppdesign(),
        undefined,
      );
    });
  setComponentsAsUnselectable(CtaContainersComponentsIds, widgetBuilder);
};
